import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { ThemeButton } from '@note/components/ThemeButton';

const SignatureFirstLastNameModal = ({
  show,
  handleClose,
  userData,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    firstName: userData?.first_name || '',
    lastName: userData?.last_name || '',
    signatureLine: userData?.signature || ''
  });

  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    signatureLine: false
  });

  React.useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.first_name || '',
        lastName: userData.last_name || '',
        signatureLine: userData.signature || ''
      });
    }
  }, [userData]);

  const isFormValid = formData.firstName.trim() !== '' &&
                     formData.lastName.trim() !== '' &&
                     formData.signatureLine.trim() !== '';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      firstName: true,
      lastName: true,
      signatureLine: true
    });

    if (!isFormValid) {
      return;
    }

    try {
      const signatureData = {
        first_name: formData.firstName.trim(),
        last_name: formData.lastName.trim(),
        signature: formData.signatureLine,
      };

      await onSave(signatureData);
      handleClose();
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  return (
    <StyledModal
      show={show}
      onHide={() => {
        if (isFormValid) {
          handleClose();
        }
      }}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalBody>
        <h3 style={{ marginBottom: '1rem' }}>Welcome to Note Designer!</h3>

        <form onSubmit={handleSubmit}>
          <div>
            <p>To get started, let's set up your signature. Please fill in the boxes below:</p>
          </div>

          <FormGroup>
            <label htmlFor="firstName">First Name:</label>
            <StyledInput
              id="firstName"
              name="firstName"
              placeholder="e.g., Roberta"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.firstName && !formData.firstName.trim()}
            />
            {touched.firstName && !formData.firstName.trim() && (
              <ErrorText>First name is required</ErrorText>
            )}
          </FormGroup>

          <FormGroup>
            <label htmlFor="lastName">Last Name:</label>
            <StyledInput
              id="lastName"
              name="lastName"
              placeholder="e.g., Hartley"
              value={formData.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.lastName && !formData.lastName.trim()}
            />
            {touched.lastName && !formData.lastName.trim() && (
              <ErrorText>Last name is required</ErrorText>
            )}
          </FormGroup>

          <FormGroup>
            <label htmlFor="signatureLine">Desired Signature Line:</label>
            <StyledTextArea
              id="signatureLine"
              name="signatureLine"
              placeholder="e.g., Roberta Hartley, PhD"
              value={formData.signatureLine}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.signatureLine && !formData.signatureLine.trim()}
              rows={3}
            />
            {touched.signatureLine && !formData.signatureLine.trim() && (
              <ErrorText>Signature line is required</ErrorText>
            )}
            <HelpText>
              [this is the text that appears below the line for your signature, at the bottom of your note. Typically this would be your name and credentials.]
            </HelpText>
          </FormGroup>
        </form>
      </ModalBody>

      <Modal.Footer>
        <ButtonContainer>
          <ThemeButton
            label="Save"
            size="lg"
            color="green"
            onClick={handleSubmit}
            disabled={!isFormValid}
          />
        </ButtonContainer>
      </Modal.Footer>
    </StyledModal>
  );
};

export default SignatureFirstLastNameModal;

const StyledModal = styled(Modal)``;

const ModalBody = styled(Modal.Body)`
  padding: 2rem;
  overflow: hidden !important;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.isInvalid ? '#dc3545' : '#ccc'};
  border-radius: 4px;
  
  &:focus {
    outline: none;
    border-color: ${props => props.isInvalid ? '#dc3545' : '#0066cc'};
    box-shadow: 0 0 0 2px ${props => props.isInvalid ? 'rgba(220, 53, 69, 0.2)' : 'rgba(0, 102, 204, 0.2)'};
  }
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.isInvalid ? '#dc3545' : '#ccc'};
  border-radius: 4px;
  resize: vertical;
  min-height: 80px;
  white-space: pre-wrap;
  
  &:focus {
    outline: none;
    border-color: ${props => props.isInvalid ? '#dc3545' : '#0066cc'};
    box-shadow: 0 0 0 2px ${props => props.isInvalid ? 'rgba(220, 53, 69, 0.2)' : 'rgba(0, 102, 204, 0.2)'};
  }
`;

const ErrorText = styled.span`
  color: #dc3545;
  font-size: 0.875rem;
  display: block;
  margin-top: 0.25rem;
`;

const HelpText = styled.p`
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
