import React from 'react';
import { Form } from 'react-bootstrap';
import SpeechToText from 'layouts/SpeechToText/SpeechToText';

const HeadingText = ({ heading, onChange, spellCheck }) => {
	const handleChange = input => {
		const value = input.target ? input.target.value : input;
		onChange(value);
	};

	return (
		<div style={{ position: 'relative' }}>
			<div style={{ position: 'absolute', top: '50%', right: '1rem', transform: 'translateY(-50%)', marginTop: 4 }}>
				<SpeechToText setter={handleChange} />
			</div>
			<Form.Control
				onChange={handleChange}
				type="text"
				value={heading.content}
				spellCheck={spellCheck}
				className={heading.name === 'Name' ? 'tour-heading-input' : ''}
				placeholder={
					heading.placeholder && heading.placeholder.length > 0 ? heading.placeholder : 'Enter ' + heading.name
				}
			/>
		</div>
	);
};

export default React.memo(HeadingText);
