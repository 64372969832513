import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Separator } from 'components/Separator';
import { ThemeButton } from 'components/ThemeButton';
import { usePreference } from 'contexts/PreferenceContext';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';
import { WidgetType } from 'utils/enum';
import {
	createStatement,
	deleteStatement,
	reorderStatements,
	updateStatement,
	likeStatement,
} from 'services/statement';
import styled from 'styled-components';
import { updateAndGetNewOrder } from 'utils/dragDropHelper';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';

import {
	isDesignPageState,
	sectionStatementsSelector,
	statementsStateSelector,
	statementsState,
	filtersState, isDemoState,
} from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import Statement from '../statements/Statement';
import SectionName from './section-name/SectionName';
import Tippy from '@tippyjs/react';

const Section = ({
	subTabType,
	subTabSettings,
	section,
	searchValue,
	filteredStems,
	removeNewSection,
	isSectionNameVisible,
	setIsLoading,
}) => {
	const { isOldSafari } = useContext(AuthContext);
	const filters = useRecoilValue(filtersState);
	const { globalPreferences } = usePreference();
	const currentStatements = useRecoilValue(statementsStateSelector);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const [filteredSectionStatements, setFilteredSectionStatements] = useState([]);
	const [temporaryStatement, setTemporaryStatement] = useState('');
	const isDesignPage = useRecoilValue(isDesignPageState);
	const isDemo = useRecoilValue(isDemoState);
	const [statements] = useRecoilState(statementsState);
	const sectionStatements = useRecoilValue(sectionStatementsSelector(section.id));

	const setStatementsInSameSubTab = useCallback(
		updatedStatement => {
			statements.forEach(s => {
				if (s.section === updatedStatement.section && s.id !== updatedStatement.id) {
					setStatement({ ...s, isSelected: false, stem: null });
				}
			});
		},
		[setStatement, statements]
	);

	useEffect(() => {
		if (searchValue) {
			// Filter statements based on the search value
			setFilteredSectionStatements(
				sectionStatements.filter(
					statement =>
						statement.section === section.id && statement.name.toLowerCase().includes(searchValue.toLowerCase())
				)
			);
		} else {
			const OriginalIds = new Set(
				sectionStatements
					?.filter(statement => statement.original_id !== null && statement.id !== statement.original_id)
					.map(statement => statement.original_id)
			);

			const ids = new Set();
			const finalOriginalIds = new Set();

			const shouldAddStatement = statement => {
				const { id, original_id } = statement;
				const isIdInSets = ids.has(id) || finalOriginalIds.has(original_id);
				const shouldAddId = !OriginalIds.has(id) && !isIdInSets;

				if (shouldAddId) {
					ids.add(id);
					if (original_id) {
						finalOriginalIds.add(original_id);
					}
				}

				return shouldAddId;
			};

			let newSectionStatements = sectionStatements.filter(shouldAddStatement);

			newSectionStatements = newSectionStatements.sort((a, b) => {
				if (a.order_index === null && b.order_index === null) {
					return 0; // Keep the original order for statements with null order_index
				}

				if (a.order_index === null) {
					return 1; // Move statement with null order_index to the bottom
				}

				if (b.order_index === null) {
					return -1; // Keep statement with null order_index above sorted statements
				}

				return a.order_index - b.order_index; // Sort normally
			});

			setFilteredSectionStatements(newSectionStatements);
		}
	}, [section.id, searchValue, sectionStatements, currentStatements]);

	// Handle adding a new statement
	const handleAddStatement = useCallback(() => {
		setStatement({
			id: Math.random(),
			name: '',
			section: section.id,
			isNew: true,
			hidden: false,
			likes: filters.showLiked ? true : false,
			order_index:
				filteredSectionStatements.map(st => st.order_index).length === 0
					? 0 // If there are no statements, set order_index to 0
					: Math.max(...filteredSectionStatements.map(st => st.order_index)) + 1, // Otherwise, set order_index to the max value plus 1
			content: '',
		});
	}, [filteredSectionStatements, filters.showLiked, section.id, setStatement]);

	// Create a new statement
	const createNewStatement = useCallback(
		async statement => {
			const res = await createStatement({
				order_index: statement.order_index,
				name: statement.name,
				section: statement.section,
				placeholder: statement.placeholder,
				prefilled_text: statement.prefilled_text,
				likes: statement.likes,
			});
			filters.showLiked && (await likeStatement(res.data.id));

			setStatement({
				...res.data,
				isSelected: false,
				selectedType: null,
				content: '',
				selectedOption: null,
				editedName: res.data.name,
				editedPrefilledText: res.data.prefilled_text,
				likes: filters.showLiked ? true : false,
			});
		},
		[filters.showLiked, setStatement]
	);

	// Update a statement
	const update = useCallback(
		async statement => {
			const res = await updateStatement(statement);
			setStatement({ remove: true, id: statement.id });
			setStatement({
				...res.data,
				isSelected: false,
				selectedType: null,
				content: '',
				selectedOption: null,
			});
		},
		[setStatement]
	);

	// Remove a statement
	const remove = useCallback(
		async id => {
			const res = await deleteStatement(id);
			setStatement({ remove: true, id: id });
			setStatement({
				...res.data,
				isSelected: false,
				selectedType: null,
				content: '',
				selectedOption: null,
			});
		},
		[setStatement]
	);

	// Save a statement
	const saveStatement = useCallback(
		statement => {
			if (statement.isNew) {
				!statement.isTemporary &&
					createNewStatement({
						order_index: statement.order_index,
						name: statement.name,
						section: statement.section,
						placeholder: statement.placeholder,
						prefilled_text: statement.prefilled_text,
					});
				setStatement({ id: statement.id, remove: true });
			} else {
				!statement.isTemporary
					? update({
							id: statement.id,
							name: statement.name,
							placeholder: statement.placeholder,
							prefilled_text: statement.prefilled_text,
					  })
					: setStatement({ ...statement });
			}
		},
		[createNewStatement, setStatement, update]
	);

	// Save the order of statements
	const saveOrder = useCallback(
		async changedStatements => {
			if (changedStatements.length === 0) return;
			try {
				setIsLoading(true);
				await reorderStatements({
					orders: changedStatements.map(statement => {
						setStatement({ ...statement, order: statement.order });
						return { id: statement.id, order: statement.order };
					}),
				});
				setIsLoading(false);
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.SUCCESS);
				toast.success('Order saved successfully', { hideProgressBar: true });
			} catch (error) {
				setIsLoading(false);
				setFilteredSectionStatements(changedStatements);
				!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.FAILURE);
				toast.error('There was something wrong while saving the order. Please try again.', { hideProgressBar: true });
				console.error('🚀 ~ file: index.js ~ line 121 ~ Section ~ error', error);
			}
		},
		[setIsLoading, isOldSafari, globalPreferences.SoundEffects, setStatement]
	);

	// Handle the end of a drag event
	const handleDragEnd = useCallback(
		async result => {
			const changedStatements = updateAndGetNewOrder({
				values: filteredSectionStatements,
				setValues: setFilteredSectionStatements,
				result,
				isStatementsList: true,
			});
			await saveOrder(changedStatements);
		},
		[filteredSectionStatements, saveOrder]
	);

	// Render the button to add a new statement
	const renderAddStatement = () => {
		return (
			<ButtonContainer className={section.name === 'Relational' ? 'tour-add-statement' : ''}>
				<Tippy content="Not available in demo" disabled={!isDemo}>
					<div>
						<ThemeButton
							disabled={isDemo ? true : filteredSectionStatements.some(st => st?.isNew)}
							onClick={handleAddStatement}
							label="Add Permanent Statement"
							color='violet'
							icon={FaPlus}
						/>
					</div>
				</Tippy>
			</ButtonContainer>
		);
	};

	const clearTemporaryStatement = () => {
		return setTemporaryStatement('');
	};

	// Render the temporary statement input
	const renderTemporaryStatement = () => {
		const handleKeyDown = e => {
			if (e.key === 'Enter' && !e.shiftKey) {
				e.preventDefault();

				const tempStatement = {
					id: uuid(),
					name: e.target.value,
					section: section.id,
					isTemporary: true,
					isSelected: false,
					isNew: false,
					hidden: false,
					likes: false,
					order_index:
						filteredSectionStatements.map(st => st.order_index).length === 0
							? 0 // If there are no statements, set order_index to 0
							: Math.max(...filteredSectionStatements.map(st => st.order_index)) + 1, // Otherwise, set order_index to the max value plus 1
					content: '',
				};

				setStatement(tempStatement);

				const stem = tempStatement.stem === filteredStems[0]?.id ? null : filteredStems[0]?.id;
				const newState = {
					...tempStatement,
					isSelected: !tempStatement.isSelected,
					stem,
				};

				if (subTabType === WidgetType.LIST_SELECT) {
					if (newState.isSelected) {
						setStatementsInSameSubTab(newState);
					}
					setStatement(newState);
				} else {
					setStatement(newState);
				}

				clearTemporaryStatement();
				e.target.blur();
			}
		};

		const adjustHeight = element => {
			element.style.height = 'auto';
			element.style.height = element.scrollHeight + 'px';
		};

		const handleOnChange = e => {
			setTemporaryStatement(e.target.value);
			adjustHeight(e.target);
		};

		return (
			<StatementTextArea
				placeholder=" ->Type & press ENTER to add to this note only"
				rows={1}
				valid={temporaryStatement.length > 0}
				value={temporaryStatement}
				onClick={e => e.stopPropagation()}
				onChange={handleOnChange}
				onKeyDown={handleKeyDown}
			/>
		);
	};

	const renderSection = provided => {
		return (
			<div className={section.name === 'Trauma' ? 'trauma-section' : ''}>
				{isSectionNameVisible && <SectionName section={section} removeNewSection={removeNewSection} />}
				{
					<Stack
						isDoubleStacked={!isDesignPage ? subTabSettings?.listSelect?.isListDoubleStacked : false}
						ref={provided?.innerRef}
						{...provided?.droppableProps}
					>
						{isDesignPage && subTabSettings?.listSelect?.isListDoubleStacked && (
							<Message>Note: This will be stacked side by side in note.</Message>
						)}

						<AnimatePresence>
							{filteredSectionStatements.map((statement, index) => (
								<motion.div
									key={index}
									initial={{ scale: 0 }}
									animate={{ scale: 1 }}
									exit={{ scale: 0 }}
									transition={{ duration: 0.2, type: 'spring' }}
								>
									<Statement
										key={statement.id}
										statement={statement}
										filteredStems={filteredStems}
										index={index}
										deleteStatement={remove}
										subTabType={subTabType}
										subTabSettings={subTabSettings}
										optionsSpacing={globalPreferences.OptionsSpacing}
										saveStatement={saveStatement}
									/>
								</motion.div>
							))}
							{provided?.placeholder}
						</AnimatePresence>
					</Stack>
				}
			</div>
		);
	};
	return isDesignPage ? (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={section.id.toString()}>{(provided, snapshot) => renderSection(provided)}</Droppable>
			{!section?.isNew && renderAddStatement()}
			<Separator />
		</DragDropContext>
	) : (
		<>
			{renderSection()}
			{(subTabType === WidgetType.LIST_SELECT || subTabType === WidgetType.LIST_MULTI_SELECT) &&
				renderTemporaryStatement()}

			{!section?.isNew && renderAddStatement()}
			<Separator />
		</>
	);
};

export default Section;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px 0;
`;

const Stack = styled.div`
	display: grid;
	grid-template-columns: ${props => (props.isDoubleStacked ? 'minmax(0, 1fr) minmax(0, 1fr)' : '100%')};
	column-gap: 5px;
`;

const Message = styled.div`
	font-weight: bold;
	text-align: center;
	margin-bottom: 10px;
`;

const StatementTextArea = styled.textarea`
	width: 100%;
	border-radius: 5px;
	border: 1px solid #212529;
	outline: none;
	max-height: fit-content;
`;
