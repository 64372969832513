import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ThemeButton } from 'components/ThemeButton';
import React, { useRef, useState } from 'react';
import { Badge, Card, Form, ListGroup } from 'react-bootstrap';
import { FaPencilAlt, FaSave, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { navy, blue } from 'styles/colors';
import { InputType } from 'utils/enum';

const HeadingEditMode = ({ heading = {}, toggleEditMode, addNewHeading, updateHeading, deleteHeading, isAdmin = false }) => {
	const optionsRef = useRef();
	const [currHeading, setCurrHeading] = useState(heading);
	const [editingOptionIndex, setEditingOptionIndex] = useState(null);
	const [editedOptionText, setEditedOptionText] = useState('');

	const radioBtns = [
		{ label: 'Text', type: InputType.TEXT },
		{ label: 'Date', type: InputType.DATE },
		{ label: 'Time', type: InputType.TIME },
		{ label: 'Dropdown', type: InputType.SELECT },
	];

	const originalDropDown = currHeading.user === null && currHeading.type === InputType.SELECT;
	const editableDropDown =
		currHeading.original_id !== null && currHeading.user !== null && currHeading.type === InputType.SELECT;

	// Handle key press for adding new dropdown option
	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			if (e.target.value.length > 0) {
				setCurrHeading({
					...currHeading,
					options: currHeading?.options?.length > 0 ? [...currHeading?.options, e.target.value] : [e.target.value],
				});
				optionsRef.current.value = '';
			}
		}
	};

	// Handle editing an option
	const handleEditOption = optionIndex => {
		setEditingOptionIndex(optionIndex);
		setEditedOptionText(currHeading.options[optionIndex]);
	};

	// Handle saving an edited option
	const handleSaveOption = () => {
		const updatedOptions = [...currHeading.options];
		updatedOptions[editingOptionIndex] = editedOptionText;
		setCurrHeading({ ...currHeading, options: updatedOptions });
		setEditingOptionIndex(null);
	};

	// Handle canceling option editing
	const handleCancelEdit = () => {
		if (currHeading.type === 'date') {
			return null;
		}

		setEditingOptionIndex(null);
		setEditedOptionText('');
	};

	// Handle removing an option
	const handleRemove = removedOption => {
		setCurrHeading({ ...currHeading, options: currHeading.options.filter(option => option !== removedOption) });
	};

	// Handle dragging and dropping options to reorder them
	const handleDragEnd = result => {
		if (!result.destination) {
			return;
		}

		const newOptions = Array.from(currHeading.options);
		const [reorderedItem] = newOptions.splice(result.source.index, 1);
		newOptions.splice(result.destination.index, 0, reorderedItem);

		setCurrHeading({
			...currHeading,
			options: newOptions,
		});
	};

	return (
		<InputTypeCard bg="light">
			<Card.Body>
				<Form>
					<Form.Group>
						<FormLabel>What would you like the heading label to be?</FormLabel>
						<Form.Control
							placeholder="Heading label"
							value={currHeading.name}
							onChange={e => setCurrHeading({ ...currHeading, name: e.target.value })}
							type="text"
							onKeyDown={e => {
								if (e.key === 'Enter') e.preventDefault();
							}}
						/>
					</Form.Group>
					{originalDropDown || (editableDropDown && <br />)}
					{!originalDropDown && !editableDropDown && (
						<div key="radio" className="my-3">
							<FormLabel>What type of heading input would you like to use?</FormLabel>
							{radioBtns.map(btn => (
								<Form.Check
									id={`heading-input-${btn.label}`}
									key={btn.label}
									type="radio"
									label={btn.label}
									onChange={() => setCurrHeading({ ...currHeading, type: btn.type })}
									value={btn.type}
									checked={currHeading.type === btn.type}
									name="InputTypeEditMode"
								/>
							))}
						</div>
					)}
					{currHeading.type === InputType.TEXT && (
						<Form.Group className="mt-3">
							<FormLabel>Placeholder</FormLabel>
							<Form.Control
								placeholder="Enter a prompt here if you wish"
								value={currHeading.placeholder}
								onChange={e => setCurrHeading({ ...currHeading, placeholder: e.target.value })}
								type="text"
							/>
						</Form.Group>
					)}
					{currHeading.type === InputType.SELECT && (
						<>
							<FormLabel>Dropdown options</FormLabel>
							<Form.Control
								type="text"
								ref={optionsRef}
								onKeyPress={handleKeyPress}
								placeholder="Type and *PRESS ENTER* to add a new dropdown option"
							/>
							<DragDropContext onDragEnd={handleDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<ListGroup className="mt-1" variant="flush" ref={provided.innerRef} {...provided.droppableProps}>
											{provided.placeholder}
											{currHeading?.options?.length > 0 &&
												currHeading.options.map((option, index) => (
													<Draggable key={`${option}${index}`} draggableId={`${option}${index}`} index={index}>
														{provided => (
															<ListGroup.Item
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																key={`item-${option}${index}`}
																ref={provided.innerRef}
																className="d-flex justify-content-between align-items-start"
																data-react-beautiful-dnd-drag-handle={{ cursor: 'move' }}
															>
																{editingOptionIndex === index ? (
																	<>
																		<Form.Control
																			style={{ marginRight: '7px' }}
																			type="text"
																			value={editedOptionText}
																			onChange={e => setEditedOptionText(e.target.value)}
																			onKeyDown={e => {
																				if (e.key === 'Enter') {
																					handleSaveOption();
																				}
																			}}
																		/>
																		{provided.placeholder}
																		<div style={{ display: 'flex', transform: 'translateY(7px)' }}>
																			<Badge
																				style={{ marginRight: '7px' }}
																				bg="success"
																				pill
																				onClick={handleSaveOption}
																			>
																				<FaSave style={{ transform: 'translate(1px, -1px)' }} />
																			</Badge>
																			<Badge bg="danger" pill onClick={handleCancelEdit}>
																				<FaTimes style={{ transform: 'translateY(-1px)' }} />
																			</Badge>
																		</div>
																	</>
																) : (
																	<>
																		<div className="ms-2 me-auto">{option}</div>
																		<Badge
																			variant="default"
																			style={{ marginRight: '7px', background: `${blue}` }}
																			bg=""
																			pill
																			onClick={() => handleEditOption(index)}
																		>
																			<FaPencilAlt style={{ transform: 'translate(1px, -1px)' }} />
																		</Badge>
																		<Badge bg="danger" pill onClick={() => handleRemove(option)}>
																			<FaTimes style={{ transform: 'translateY(-1px)' }} />
																		</Badge>
																	</>
																)}
															</ListGroup.Item>
														)}
													</Draggable>
												))}
										</ListGroup>
									)}
								</Droppable>
							</DragDropContext>
						</>
					)}
				</Form>
			</Card.Body>
			<CardFooter>
				<ButtonContainer>
					{!heading.isNew && (isAdmin || (!originalDropDown && currHeading.user !== null)) && (
						<ThemeButton color="red" onClick={() => deleteHeading(currHeading.id)} label="Delete" />
					)}
					{heading.isNew ? (
						<ThemeButton
							onClick={() => addNewHeading(currHeading)}
							label="Save"
							disabled={currHeading.name.trim().length === 0}
						/>
					) : (
						<ThemeButton
							onClick={() => updateHeading(currHeading)}
							label="Update"
							disabled={currHeading.name.trim().length === 0}
						/>
					)}
					<ThemeButton label="Cancel" color="grey" onClick={() => toggleEditMode(heading)} />
				</ButtonContainer>
			</CardFooter>
		</InputTypeCard>
	);
};

export default HeadingEditMode;

const InputTypeCard = styled(Card)`
	margin-bottom: 10px;
	border: 1.5px solid ${navy}40;
`;

const CardFooter = styled(Card.Footer)`
	display: flex;
	justify-content: flex-end;
	border: 0;
`;

const ButtonContainer = styled.div`
	display: inline-flex;
	column-gap: 5px;
`;

const FormLabel = styled(Form.Label)`
	font-weight: bold;
`;
