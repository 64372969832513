import { Draggable } from '@hello-pangea/dnd';
import { useNote } from 'contexts/NoteContext';
import DeleteConfirmationModal from 'modals/DeleteConfirmationModal';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { isDemoState, isDesignPageState } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';
import HeadingEditMode from './HeadingEditMode';
import HeadingLabel from './HeadingLabel';

const HeadingItem = ({
	heading,
	toggleEditMode,
	addNewHeading,
	updateHeading,
	deleteHeading,
	inputType: InputType,
	setHeadings,
	headings,
	index,
}) => {
	const { isAdmin, user } = useContext(AuthContext);
	const { isCustomizable } = useNote();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const isDemo = useRecoilValue(isDemoState);

	const onChange = useCallback(
		value => {
			setHeadings(
				headings.map(currHeading => (currHeading.id === heading.id ? { ...currHeading, content: value } : currHeading))
			);
		},
		[heading, headings, setHeadings]
	);

	const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);
	const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);

	const isHeadingDeleteAllowed = useMemo(() => {
		if (isAdmin) return true;
		else if (user && heading) return heading.user === user.id;
	}, [heading, isAdmin, user]);

	return (
		<Draggable
			key={heading.id}
			draggableId={heading.id.toString()}
			index={index}
			isDragDisabled={!isDesignPage || heading?.editMode}
		>
			{(provided, snapshot) => (
				<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
					{heading.editMode ? (
						<>
							<HeadingEditMode
								heading={heading}
								toggleEditMode={toggleEditMode}
								addNewHeading={addNewHeading}
								updateHeading={updateHeading}
								deleteHeading={openDeleteModal}
								isAdmin={isAdmin}
							/>
							{provided.placeholder}
						</>
					) : (
						<div className="input-container">
							<HeadingLabel
								heading={heading}
								toggleEditMode={toggleEditMode}
								isEditAllowed={isDemo ? false : heading.type === 4 ? true : isCustomizable ? isCustomizable : isHeadingDeleteAllowed}
								spellCheck={!heading.name.toLowerCase().includes('name')}
							/>
							{InputType && (
								<InputType
									heading={heading}
									onChange={onChange}
									className="heading-input"
									spellCheck={!heading.name.toLowerCase().includes('name')}
								/>
							)}
						</div>
					)}
					{showDeleteModal && (
						<DeleteConfirmationModal
							id={heading.id}
							name={heading.name}
							entityType="heading"
							deleteEntity={deleteHeading}
							show={showDeleteModal}
							handleClose={closeDeleteModal}
						/>
					)}
				</div>
			)}
		</Draggable>
	);
};

export default HeadingItem;
