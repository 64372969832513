import { useNote } from 'contexts/NoteContext';
import { usePreference } from 'contexts/PreferenceContext';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import RadioInput from '../RadioInput';

// Options for the prompt builder
const promptOptionsList = {
	length: {
		name: 'length',
		label: 'Length',
		values: [
			{
				key: 'comprehensive',
				text: 'of moderate length.',
			},
			{
				key: 'more concise',
				text: 'relatively short and concise.',
			},
		],
	},
	pronouns: {
		name: 'pronouns',
		label: 'Pronouns',
		values: [
			{
				key: 'they / them',
				text: 'they/them/theirs pronouns to refer to the patient,',
			},
			{
				key: 'she / her',
				text: 'she/her/hers pronouns to refer to the female patient, ',
			},
			{
				key: 'he / him',
				text: 'he/him/his pronouns to refer to the male patient,',
			},
		],
	},
	formatoptions: {
		name: 'formatoptions',
		label: 'Note format',
		values: [
			{
				key: 'narrative',
				text: 'Use the form of a narrative',
			},
			{
				key: 'point form',
				text: 'Use the form of bullet-points',
			},
		],
	},
	language: {
		name: 'language',
		label: 'Language',
		values: [
			{
				key: 'English',
				text: 'English',
			},
			{
				key: 'French',
				text: 'French',
			},
			{
				key: 'Spanish',
				text: 'Spanish',
			},
			{
				key: 'Portuguese',
				text: 'Portuguese',
			},
			{
				key: 'German',
				text: 'German',
			},
			{
				key: 'Italian',
				text: 'Italian',
			},
		],
	},
};

export const GlobalAiPromtBuilder = () => {
	const { setGlobalAIPrompt } = useNote();
	const { multiLevelPreferences } = usePreference();

	// State to manage the selected prompt options
	const [promptOptions, setPromptOptions] = useState({
		formatoptions: { value: 'Use the form of a narrative' },
		pronouns: { value: 'they/them/theirs pronouns to refer to the patient,' },
		length: { value: 'of moderate length.' },
		language: { value: 'English' },
	});
	const pronounString = promptOptions?.pronouns?.value ? promptOptions.pronouns.value : '';

	// Handle changes to the prompt options
	const handleChange = (param, value) => {
		setPromptOptions(prevOptions => ({
			...prevOptions,
			[param]: { value },
		}));
	};

	// Update the filled prompt whenever any of the selected values change
	useEffect(() => {
		const lengthValue = promptOptions?.length?.value;
		const newFilledPrompt = {
			messages: `<|system|> You are a professional psychotherapist with outstanding writing skills. Please revise each section of the following psychotherapy note in a more integrated, professional style, adding pronouns frequently while preserving all the information including biopsychosocial factors if provided. (Please retain the term biopsychosocial if used in the input.) Importantly, your response MUST use exactly the same section $$%% headings %%$$ as indicated with dollar signs and percentage symbols in the input. Do not include name, date, or other headings at the top of the note, but do keep the $$%% headings %%$$, each on its own line. Please do not use the word "abuse" to refer to the problematic use of substances or to substance use difficulties of any kind. Be careful to maintain the difference between "observed" versus "reported" affective/emotional states. Please do not use the term "conversation" when referring to the discussion/interview/assessment during the session. Importantly, be sure to use ${pronounString} as well as using the term used in the input to refer to the person receiving the treatment (e.g., client/patient/Veteran/service member). If any estimated level of risk to self (or other) is submitted, retain this statement verbatim. Integrate all the following information without adding any new information:</s><|user|>`,
			useBulletPoints: promptOptions?.formatoptions?.value === 'Use the form of a bullet-points',
			language: promptOptions?.language?.value,
			length: lengthValue === 'relatively short and concise.' ? 'concise' : undefined,
		};

		setGlobalAIPrompt(newFilledPrompt);
	}, [
		pronounString,
		multiLevelPreferences.clientTerminology,
		promptOptions?.formatoptions?.value,
		promptOptions?.language?.value,
		setGlobalAIPrompt,
		promptOptions?.length?.value,
	]);

	// Render the choices for a given parameter
	const renderChoices = param => {
		const { label, values } = promptOptionsList[param];
		return (
			<Form.Group style={{ marginBottom: '1rem' }}>
				<h5>{label}</h5>
				{values.map(option => (
					<RadioInput
						key={option.key}
						value={option.text}
						label={option.key}
						currentValue={promptOptions[param]?.value}
						name={option.key}
						handleChange={() => handleChange(param, option.text)}
						checked={promptOptions[param]?.value === option.text}
					/>
				))}
			</Form.Group>
		);
	};

	return (
		<PromptBuilderContainer>
			<Form>
				{renderChoices('formatoptions')}
				{renderChoices('length')}
				{renderChoices('pronouns')}
				{renderChoices('language')}
			</Form>
		</PromptBuilderContainer>
	);
};

export default GlobalAiPromtBuilder;

// Styled component for the prompt builder container
const PromptBuilderContainer = styled.div`
	overflow-y: auto;
`;
