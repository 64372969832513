import { ThemeButton } from 'components/ThemeButton';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { usePreference } from 'contexts/PreferenceContext';
import AIEditModal from '@note/features/aiEdit/AIEditModal';
import { useNote } from 'contexts/NoteContext';
import ExportModal from 'features/export/ExportModal';
import FinalEditModal from 'features/export/FinalEditModal';
import { AIEditConfirmationModal } from 'modals/AIEditConfirmationModal';
import ClearConfirmationModal from 'modals/ClearConfirmationModal';
import CopyModal from 'modals/CopyModal';
import { FinalNoteConfirmationModal } from 'modals/FinalNoteConfirmationModal';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import { FaCopy, FaEdit, FaEraser, FaLaptop, FaPrint } from 'react-icons/fa';
import { copyToClipboard, copyTextToClipboard } from 'utils/copyToClipboard';
import { exportPdf } from 'utils/exportPdf';
import { getMyInfo, acceptGlobalAIConsent, acceptAINoteConsent } from '@note/services/user';
import { soundKeys, soundPlayer } from 'utils/soundPlayer';
import { endAIPlus, showAIPlus } from 'services/aiEdit';
import GlobalAIConsentModal from '@note/modals/GlobalAIConsentModal';
import AINoteConsentModal from '@note/modals/AINoteConsentModal';
import { updatePreference } from '@note/services/preference';
import { blue } from 'styles/colors';
import { PreferenceTypes } from '../../utils/enum';

import { AuthContext } from '@note/contexts/AuthContext';
import { useRecoilValue } from 'recoil';
import { isDemoState } from '@note/atoms';
import Tippy from '@tippyjs/react';

const NoteContentHeader = ({ clearNote, introComment, setIntroComment, closingComment, setClosingComment }) => {
	const [showGlobalAIConsent, setShowGlobalAIConsent] = useState(false);
	const [showAINoteConsent, setShowAINoteConsent] = useState(false);
	const { globalPreferences, setPreferences, preferences } = usePreference();
	const { user, isOldSafari, setUser, isAdmin } = useContext(AuthContext);
	const { activeNoteType } = useNote();
	const [iframeURL, setIframeURL] = useState();
	const [showClear, setShowClear] = useState(false);
	const [showExport, setShowExport] = useState(false);
	const [showAIEdit, setShowAIEdit] = useState(false);
	const [showFinalEdit, setShowFinalEdit] = useState(false);
	const [showGlobalAIConsentModal, setShowGlobalAIConsentModal] = useState(false);
	const [showAINoteConsentModal, setShowAINoteConsentModal] = useState(false);
	const [showCopy, setShowCopy] = useState(false);
	const [isFinalNoteConfirmationModalOpen, setIsFinalNoteConfirmationModalOpen] = useState(false);
	const [isAIEditConfirmationModalOpen, setAIEditConfirmationModalOpen] = useState(false);
	const isDemo = useRecoilValue(isDemoState);

	useEffect(() => {
		// Show AI note consent if the note type is AI and user hasn't accepted GPT terms
		activeNoteType?.is_ai_note && setShowAINoteConsent(!user?.has_accepted_gpt_terms);
		(async () => {
			const userInfo = await getMyInfo();
			if (preferences) {
				try {
					let aiRewritePreference = preferences.find(pref => pref.key === PreferenceTypes.AI_REWRITE);
					setShowGlobalAIConsent(
						aiRewritePreference?.value.toLowerCase() !== 'enabled' || !userInfo.data[0].has_accepted_ai_terms
					);
				} catch (error) {
					console.error('Failed to fetch user info:', error);
				}
			}
		})();
	}, [activeNoteType?.is_ai_note, preferences, user?.has_accepted_gpt_terms]);

	const openExport = () => {
		// Open export modal and play sound if not on old Safari
		!isOldSafari && globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.HARPFLOURISH);
		exportPdf({
			id: 'note',
			fontSize: globalPreferences?.FontSize,
			fontStyle: globalPreferences?.FontStyle,
			setIframeURL,
			setShowExport,
			isNoteOutput: true,
		});
	};

	const closeExport = useCallback(() => setShowExport(false), []);
	const openFinalEdit = useCallback(() => setShowFinalEdit(true), []);
	const closeFinalEdit = useCallback(() => setShowFinalEdit(false), []);
	const openAIEdit = useCallback(() => setShowAIEdit(true), []);
	const closeAIEdit = useCallback(() => {
		setShowAIEdit(false);
		activeNoteType?.is_ai_plus_note && endAIPlus(activeNoteType.id);
	}, []);
	const openClearModal = useCallback(() => setShowClear(true), []);
	const closeClearModal = useCallback(() => setShowClear(false), []);
	const openFinalNoteConfirmationModal = useCallback(() => setIsFinalNoteConfirmationModalOpen(true), []);
	const closeFinalNoteConfirmationModal = useCallback(() => setIsFinalNoteConfirmationModalOpen(false), []);
	const openAIEditConfirmationModal = useCallback(() => setAIEditConfirmationModalOpen(true), []);
	const closeAIEditConfirmationModal = useCallback(() => {
		setAIEditConfirmationModalOpen(false);
	}, []);

	const openGlobalAIConsentModal = () => {
		setShowGlobalAIConsentModal(true);
	};

	const closeGlobalAIConsentModal = () => {
		setShowGlobalAIConsentModal(false);
	};
	const closeAINoteConsentModal = () => setShowAINoteConsentModal(false);
	const closeCopy = () => setShowCopy(false);
	const openCopy = () => setShowCopy(true);

	const handleGlobalAIConsentAccept = async () => {
		if (user) {
			try {
				await acceptGlobalAIConsent(user.id);
				const updatedAIPref = await updatePreference({
					id: user.ai_rewrite.id,
					value: 'Enabled',
				});
				const updatedTitlePref = await updatePreference({
					id: user.tab_heading.id,
					value: 'Show title',
				});
				const updatedTitleBreakPref = await updatePreference({
					id: user.tab_break.id,
					value: 'Show breaks',
				});
				const updatedSubtitlePref = await updatePreference({
					id: user.subtab_heading.id,
					value: 'Hide title',
				});
				const updatedSubtitleBreakPref = await updatePreference({
					id: user.subtab_break.id,
					value: 'Hide breaks',
				});
				setUser({
					...user,
					has_accepted_ai_terms: true,
					ai_rewrite: { ...user.ai_rewrite, value: 'Enabled' },
				});
				activeNoteType?.id && showAIPlus(activeNoteType.id);

				const setUpdatedPreference = prefToUpdate => {
					setPreferences(currentPreferences => {
						const prefIndex = currentPreferences?.findIndex(pref => pref.key === prefToUpdate?.data?.key);
						if (prefIndex !== -1) {
							const newPreferences = [...currentPreferences];
							newPreferences[prefIndex] = prefToUpdate.data;
							return newPreferences;
						}
						return [...currentPreferences, prefToUpdate.data];
					});
				};

				setUpdatedPreference(updatedAIPref);
				setUpdatedPreference(updatedTitlePref);
				setUpdatedPreference(updatedSubtitlePref);
				setUpdatedPreference(updatedTitleBreakPref);
				setUpdatedPreference(updatedSubtitleBreakPref);
				openAIEdit();
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleAINoteConsentAccept = async () => {
		if (user) {
			try {
				await acceptAINoteConsent(user.id);
				setUser({
					...user,
					has_accepted_gpt_terms: true,
				});
				openAIEdit();
			} catch (error) {
				console.error(error);
			}
		}
	};

	const handleCopy = async isSimpletext => {
		if (!navigator.userAgent.includes('Firefox')) {
			try {
				globalPreferences.SoundEffects === 'Enabled' && soundPlayer(soundKeys.HARPFLOURISH);
				isSimpletext
					? copyTextToClipboard(document.getElementById('note'), 'signature-controls')
					: copyToClipboard(document.getElementById('note'), 'signature-controls');
			} catch (error) {
				openCopy();
			}
		} else {
			openCopy();
		}
	};

	const handleAiRewriteClick = () => {
		if (activeNoteType?.is_ai_note) {
			!isAdmin && showAINoteConsent ? setShowAINoteConsentModal(true) : openAIEdit();
		} else {
			!isAdmin && !activeNoteType?.is_ai_note && showGlobalAIConsent ? openGlobalAIConsentModal() : openAIEdit();
		}
	};

	return (
		<div className="note-content-header">
			<div className="note-content-header-left-btn-group">
				<ThemeButton label="Export" icon={FaPrint} onClick={openExport} color="blue" tourAnchor="tour-export" />
				<Dropdown>
					<DropdownToggle id="dropdown-basic" className="tour-copy">
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<FaCopy style={{ marginRight: 5, transform: 'translateY(4px)' }} /> <>Copy</>
						</div>
					</DropdownToggle>
					<Dropdown.Menu align="end">
						<Dropdown.Item onClick={() => handleCopy(false)}>Formatted copy</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item onClick={() => handleCopy(true)}>Copy Text Only</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<Tippy content="Not available in demo" disabled={!isDemo}>
					<span style={{ display: 'inline-flex' }}>
						<ThemeButton
							label="AI rewrite"
							onClick={handleAiRewriteClick}
							icon={FaLaptop}
							color="blue"
							tourAnchor="tour-aiedit"
							disabled={isDemo}
						/>
          			</span>
				</Tippy>
				<ThemeButton
					label="Final edit"
					onClick={openFinalEdit}
					icon={FaEdit}
					color="blue"
					tourAnchor="tour-finaledit"
				/>
			</div>
			<ThemeButton label="Clear" icon={FaEraser} color="red" onClick={openClearModal} tourAnchor="tour-clear" />
			{showExport && <ExportModal iframeURL={iframeURL} show={showExport} handleClose={closeExport} />}
			{showAIEdit && (
				<AIEditModal
					fontSize={globalPreferences?.FontSize}
					fontStyle={globalPreferences?.FontStyle}
					show={showAIEdit}
					handleClose={closeAIEdit}
					introComment={introComment}
					setIntroComment={setIntroComment}
					closingComment={closingComment}
					setClosingComment={setClosingComment}
					openAIEditConfirmationModal={openAIEditConfirmationModal}
					showAISettings={!showGlobalAIConsentModal}
				/>
			)}
			{showFinalEdit && (
				<FinalEditModal
					fontSize={globalPreferences?.FontSize}
					fontStyle={globalPreferences?.FontStyle}
					show={showFinalEdit}
					handleClose={closeFinalEdit}
					introComment={introComment}
					setIntroComment={setIntroComment}
					closingComment={closingComment}
					setClosingComment={setClosingComment}
					openFinalNoteConfirmationModal={openFinalNoteConfirmationModal}
				/>
			)}
			{showClear && <ClearConfirmationModal clearNote={clearNote} show={showClear} handleClose={closeClearModal} />}
			{isFinalNoteConfirmationModalOpen && (
				<FinalNoteConfirmationModal
					closeFinalEditModal={closeFinalEdit}
					show={isFinalNoteConfirmationModalOpen}
					handleClose={closeFinalNoteConfirmationModal}
				/>
			)}
			{isAIEditConfirmationModalOpen && (
				<AIEditConfirmationModal
					closeAIEditModal={closeAIEdit}
					show={isAIEditConfirmationModalOpen}
					handleClose={closeAIEditConfirmationModal}
				/>
			)}
			{showCopy && <CopyModal show={showCopy} handleClose={closeCopy} />}
			{showGlobalAIConsentModal && (
				<GlobalAIConsentModal
					show={showGlobalAIConsentModal}
					handleClose={closeGlobalAIConsentModal}
					onConsentAccept={handleGlobalAIConsentAccept}
					onConsentCancel={closeGlobalAIConsentModal}
					useShortForm={false}
				/>
			)}
			{showAINoteConsentModal && (
				<AINoteConsentModal
					show={showAINoteConsentModal}
					handleClose={closeAINoteConsentModal}
					onConsentAccept={handleAINoteConsentAccept}
					onConsentCancel={closeAINoteConsentModal}
				/>
			)}
		</div>
	);
};

export default NoteContentHeader;

const DropdownToggle = styled(Dropdown.Toggle)`
	background: ${blue};
	height: 100%;
	& > button {
		height: 100% !important;
	}
	&:after {
		display: none;
	}
	&:hover {
		background: ${blue};
	}
`;
