import React, {useContext, useState} from 'react';
import { Modal } from 'react-bootstrap';

import { ThemeButton } from '@note/components/ThemeButton';
import { acceptTerms, getSignature, updateUserDetails } from '@note/services/user';
import {AuthContext} from "@note/contexts/AuthContext";
import NewNoteModal from '@note/layouts/note-type/NewNoteModal';
import SignatureModal from '@note/layouts/note-output/signature/SignatureModal';
import SignatureFirstLastNameModal from '@note/modals/SignatureFirstLastNameModal';

const TermsAndConditionsModal = ({ show }) => {
	const { user, setUser, setSignature, setSignatureImage, setSignatureTextObject } = useContext(AuthContext);
	const [closeModal, setCloseModal] = useState(false);
    const [showNewNoteModal, setShowNewNoteModal] = useState(false);
    const [showSignatureModal, setShowSignatureModal] = useState(false);

	const handleAccept = async () => {
		try {
			const { data } = await acceptTerms(user.id);
			setUser({ ...user, has_accepted_terms: data.has_accepted_terms });
            setShowSignatureModal(true);
			setCloseModal(true)
		} catch (error) {
			console.error('🚀 ~ file: TermsAndConditionsModal.js ~ line 17 ~ handleAccept ~ error', error);
		}
	};

    const handleSignatureClose = async () => {
        const { data } = await getSignature();
        setSignature(data.signature);
        setSignatureImage(data.signature_image);
        setSignatureTextObject(data.signature_json);
        setShowSignatureModal(false);
        setShowNewNoteModal(true);
    };

    return (
      <>
        <Modal show={show && !user?.has_accepted_terms}  backdrop="static" scrollable centered size="lg">
          <Modal.Header>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2>Note Designer: End User License Agreement</h2>
            <p>This is a copy of the Note Designer End-User License Agreement (EULA)</p>
            <p>
              Please read the following terms and conditions carefully before using this SOFTWARE PRODUCT. This End-User
              License Agreement (EULA) is a legal agreement between you and NOTE DESIGNER INC. (previously MINDHABITS,
              Inc.), the distributor of NOTE DESIGNER, which governs your use of NOTE DESIGNER. Your use or installation of
              this copy of NOTE DESIGNER indicates your acceptance of this License.
            </p>
            <p>
              SOFTWARE PRODUCT here means Software, image files, web pages, all accompanying files, data and materials
              (including the psychotherapy-related statements and phrases) received with your order of NOTE DESIGNER. If you
              do not agree to any of the terms of this License, then do not install or use the SOFTWARE PRODUCT. If you have
              purchased a copy from NOTE DESIGNER, INC. or an authorized distributor or authorized retail channel, you may
              return it, within thirty (30) days after purchase, for a refund of your payment.
            </p>
            <p>
              This SOFTWARE PRODUCT is for use only by one individual, the authorized user, in conjunction with an
              authorization code and/or user account based on the user’s name and/or email address, and provided by NOTE
              DESIGNER INC. It may be used on more than one computer by the authorized user, but it may not be distributed
              to others. SOFTWARE PRODUCT may be used by more than one individual only in accordance with a group/site
              license agreement. All components accompanying the software are copyrighted by NOTE DESIGNER, INC. and may not
              be taken apart, modified, used or published with other software or means except with the SOFTWARE PRODUCT
              software and may not be distributed or copied in any manner.
            </p>
            <p>
              To the maximum extent permitted under applicable law, this SOFTWARE PRODUCT, all accompanying files, data and
              materials, are distributed AS IS and with no warranties of any kind, whether express or implied. The user must
              assume all risk of using the program. This disclaimer of warranty constitutes an essential part of the
              agreement.
            </p>
            <p>
              YOU AGREE THAT THE USE OF THE SOFTWARE PRODUCT IS AT YOUR SOLE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
              ACCURACY AND EFFORT. NOTE DESIGNER INC. does NOT warrant that the functions contained in the Software Product
              will meet your requirements or that the operation of the Software Product will be uninterrupted or error free
              or that such errors will be corrected. Computer software is inherently subject to bugs and potential
              incompatibility with other computer software and hardware. You should not use the Software Product for any
              application in which failure could cause any significant damage or injury to persons or tangible or intangible
              property. To the maximum extent permitted by applicable law, in no event shall NOTE DESIGNER INC. or its
              suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but
              not limited to, damages for loss of profits, for loss of data or other information, for business interruption,
              for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to
              use the software product, third party software and/or third party hardware used with the software product, or
              otherwise in connection with any provision of this EULA), even if NOTE DESIGNER INC. or any supplier has been
              advised of the possibility of such damages and even if the remedy fails of its essential purpose. You
              acknowledge that maintenance of proper clinical notes and the confidentiality of information is your sole
              responsibility. NOTE DESIGNER INC. does NOT warrant that the functions and content contained in the Software
              Product will meet your requirements or the requirements of any professional organization or regulatory body.
              NOTE DESIGNER INC. cannot guarantee that database functions that may be contained in the software are
              completely secure from access or decoding by third parties. Accordingly, NOTE DESIGNER INC. will not be
              responsible for any losses or damages whatsoever resulting from third party decoding of, or access to, your
              files. The software may contain open source materials (e.g., any software subject to open source, copyleft,
              GNU general public license, library general public license, lesser general public license, Mozilla license,
              Berkeley software distribution license, open source initiative license, MIT, Apache, or public domain
              licenses, or similar license). NOTE DESIGNER INC. makes no warranties, and shall have no liability, direct or
              indirect, whatsoever with respect to open source materials contained in the software. Any liability of NOTE
              DESIGNER, INC. will be limited exclusively to refund of purchase price. In addition, in no event shall NOTE
              DESIGNER, INC., or its principals, shareholders, officers, employees, affiliates, contractors, subsidiaries,
              or parent organizations, be liable for any incidental, consequential, punitive or any other damages whatsoever
              relating to the use of SOFTWARE PRODUCT. You agree to indemnify and hold NOTE DESIGNER, INC. harmless from any
              claims resulting from the use of this SOFTWARE PRODUCT, which may damage any other party.
            </p>
            <p>
              NOTE DESIGNER, INC. holds the rights to this SOFTWARE PRODUCT, and all of its derivatives, title and
              accompanying materials. NOTE DESIGNER grants to the licensee the right to use the psychotherapy-related
              phrases and statements in the SOFTWARE PRODUCT for the intended purpose of preparing their own
              psychotherapy-related notes. All rights of any kind, which are not expressly granted in this License, are
              entirely and exclusively reserved to and by NOTE DESIGNER, INC. You may not rent, lease, transfer, modify,
              distribute, sublicense, translate, reverse engineer, de-compile, disassemble or create derivative works based
              on this SOFTWARE PRODUCT or the content therein. You may not disclose to other persons the data or techniques
              relating to this SOFTWARE PRODUCT that you know or should know that it is a trade secret of NOTE DESIGNER,
              INC. in any manner that will cause damage to NOTE DESIGNER, INC. If you breach these restrictions, you may be
              subject to prosecution and damages.
            </p>
            <p>
              This Agreement constitutes the entire statement of the Agreement between the parties on the subject matter,
              and merges and supersedes all other or prior understandings, purchase orders, agreements and arrangements.
              This Agreement shall be governed by the laws of the Province of Quebec. This EULA is effective unless
              terminated or rejected. This EULA will also terminate immediately and without additional notice in the event
              you breach this EULA and/or fail to comply with any term or condition of this EULA.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <ThemeButton label="Accept" size="lg" color="green" onClick={handleAccept} />
          </Modal.Footer>
        </Modal>
        <>
          <SignatureFirstLastNameModal
              show={showSignatureModal}
              handleClose={handleSignatureClose}
              userData={user}
              onSave={async (updatedData) => {
                try {
                  const { data } = await updateUserDetails(user.id, updatedData);
                  setUser({ ...user, ...data });
                  handleSignatureClose();
                } catch (error) {
                  console.error('Error saving user data:', error);
                }
              }}
          />
        </>
        <NewNoteModal
            show={showNewNoteModal}
            handleClose={() => setShowNewNoteModal(false)}
            fromTermsAcceptance={true}
        />
      </>
    );
};

export default TermsAndConditionsModal;
