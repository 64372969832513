import { usePreference } from 'contexts/PreferenceContext';
import React, { useState, useCallback } from 'react';
import { FaPencilAlt, FaRegHeart, FaHeart } from 'react-icons/fa';
import { likeStatement } from 'services/statement';
import styled from 'styled-components';
import { navy, purple } from 'styles/colors';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { statementsStateSelector, filtersState, isDemoState } from '@note/atoms';

import StatementInput from './StatementInput';

const StatementText = ({
	statement,
	statementText,
	setStatementText,
	toggleStatement,
	isSelected,
	editMode,
	toggleEdit,
	isLibraryStatement = false,
	isSingleLine = false,
}) => {
	const filters = useRecoilValue(filtersState);
	const setStatement = useSetRecoilState(statementsStateSelector);
	const { globalPreferences } = usePreference();
	const [showIcons, setShowIcons] = useState(false);
	const isDemo =  useRecoilValue(isDemoState);

	// Handlers to show/hide icons when mouse enters/leaves the statement container
	const handleContainerMouseEnter = useCallback(() => {
		setShowIcons(true);
	}, []);

	const handleContainerMouseLeave = useCallback(() => {
		setShowIcons(false);
	}, []);

	// Toggle the like status of a statement
	const toggleLike = async event => {
		event.stopPropagation();

		// Check if the filters allow liking and update the statement
		!filters.showLiked && !statement.isTemporary && (await likeStatement(statement.id));
		setStatement({ ...statement, likes: !statement.likes });
	};

	return (
		<StatementContainer
			isSelected={isSelected}
			onMouseEnter={handleContainerMouseEnter}
			onMouseLeave={handleContainerMouseLeave}
			opacity={globalPreferences.ColorOpacity}
			isTemporary={statement.isTemporary}
		>
			{/* Render the statement text or input field if in edit mode */}
			<Text
				onClick={toggleStatement}
				isTemporary={statement.isTemporary}
				isSelected={isSelected}
				single={isSingleLine}
				opacity={globalPreferences.ColorOpacity}
				isLibraryStatement={isLibraryStatement}
			>
				{editMode ? (
					<StatementInput
						isSingleLine={isSingleLine}
						statementText={statementText}
						setStatementText={setStatementText}
						editMode={editMode}
					/>
				) : (
					<span>{statement.name}</span>
				)}
			</Text>

			{/* Render edit and like icons if not in edit mode and icons are visible */}
			{showIcons && !editMode && !isDemo && (
				<IconsContainer>
					<StatementIcon onClick={toggleEdit}>
						<FaPencilAlt style={{ transform: 'translateY(7px)', marginBottom: 10 }} />
					</StatementIcon>
					{!statement.likes && (
						<StatementIcon onClick={toggleLike}>
							<FaRegHeart style={{ transform: 'translateY(7px)', marginBottom: 10, color: '#dc3545' }} />
						</StatementIcon>
					)}
					{statement.likes && (
						<StatementIcon onClick={toggleLike}>
							<FaHeart style={{ transform: 'translateY(7px)', marginBottom: 10, color: '#dc3545' }} />
						</StatementIcon>
					)}
				</IconsContainer>
			)}

			{/* Show the liked icon if the statement is liked and icons are not visible */}
			{statement.likes && !showIcons && (
				<StatementIcon onClick={toggleLike}>
					<FaHeart style={{ transform: 'translateY(7px)', marginBottom: 10, color: '#dc3545' }} />
				</StatementIcon>
			)}
		</StatementContainer>
	);
};

export default React.memo(StatementText);

const StatementContainer = styled.div`
	position: relative;
	overflow: visible;
	align-items: stretch;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 5px;
	background: ${props =>
		props.isSelected && props.isTemporary
			? `rgba(200, 134, 219, ${props.opacity})`
			: props.isSelected
			? `rgba(216, 221, 248, ${props.opacity})`
			: 'white'};
	border: 1px solid ${props => (props.isSelected ? (props.isTemporary ? purple : navy + '70') : 'white')};
`;

const StatementIcon = styled.button`
	background: transparent;
	border: none;
	flex: 0 0 auto;
	align-self: stretch;
	margin-top: -3px;
	height: 24px;
	width: 24px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	overflow: visible;
`;

const Text = styled.div`
	height: 100%;
	width: 100%;
	padding: 0 10px;

	${props =>
		props.single &&
		`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
	color: ${props => (props.isSelected ? (props.isTemporary ? purple : 'black') : 'black')};
	border-radius: 5px;
	cursor: pointer;
	&:hover div {
		display: block;
	}
`;

const IconsContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	height: 24px;
	width: 50px;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	overflow: visible;
`;
