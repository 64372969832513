import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { ThemeButton } from 'components/ThemeButton';
import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { FaPlus, FaLock } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { useNote } from 'contexts/NoteContext';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createHeading, deleteHeading, reorderHeadings, updateHeading } from 'services/heading';
import { updateAndGetNewOrder } from 'utils/dragDropHelper';
import { InputType } from 'utils/enum';
import { headingsState, isDemoState, isDesignPageState } from '@note/atoms';
import { AuthContext } from '@note/contexts/AuthContext';

import HeadingItem from './HeadingItem';
import HeadingDate from './inputs/HeadingDate';
import HeadingSelect from './inputs/HeadingSelect';
import HeadingText from './inputs/HeadingText';
import HeadingTime from './inputs/HeadingTime';
import Tippy from '@tippyjs/react';

// Function to get the component type based on heading type
const getInputType = heading => {
	switch (heading.type) {
		case InputType.TEXT:
			return HeadingText;
		case InputType.DATE:
			return HeadingDate;
		case InputType.SELECT:
			return HeadingSelect;
		case InputType.TIME:
			return HeadingTime;
		default:
			return HeadingText;
	}
};

const Headings = () => {
	const { user } = useContext(AuthContext);
	const { activeNoteType } = useNote();
	const [showLockState, setShowLockState] = useState(false);
	const [searchParams] = useSearchParams();
	const [headings, setHeadings] = useRecoilState(headingsState);
	const isDesignPage = useRecoilValue(isDesignPageState);
	const isDemo = useRecoilValue(isDemoState);

	// Set lock state based on user acceptance of AI terms
	useEffect(() => {
		setShowLockState(user?.has_accepted_ai_terms);
	}, [user]);

	// Process headings to ensure correct order and uniqueness
	const processedHeadings = useMemo(() => {
		const copiedOriginalIds = new Set(headings.map(heading => heading.original_id).filter(id => id != null));

		const filteredHeadings = headings.filter(heading => heading.original_id || !copiedOriginalIds.has(heading.id));

		return filteredHeadings.sort((a, b) => a.order_index - b.order_index);
	}, [headings]);

	// Toggle edit mode for the selected heading
	const toggleEditMode = useCallback(
		selectedHeading => {
			if (selectedHeading.isNew) {
				setHeadings(headings.filter(hd => !hd.isNew));
			} else {
				setHeadings(
					headings.map(heading => {
						if (heading.id === selectedHeading.id && heading.type === 3) {
							return { ...heading, content: null, editMode: !heading.editMode };
						} else if (heading.id === selectedHeading.id) {
							return { ...heading, editMode: !heading.editMode };
						}
						return heading;
					})
				);
			}
		},
		[headings, setHeadings]
	);

	// Handle click to add a new heading
	const handleAddClick = useCallback(() => {
		if (isDemo) return;

		setHeadings([
			...headings,
			{
				id: Math.random(),
				name: '',
				options: [],
				placeholder: '',
				order_index: headings.length > 0 ? Math.max(...headings.map(heading => heading.order_index)) + 1 : 0,
				type: 0,
				note_type: parseInt(searchParams.get('id')),
				editMode: true,
				isNew: true,
			},
		]);
	}, [headings, searchParams, setHeadings]);

	// Add a new heading
	const addNewHeading = useCallback(
		async selectedHeading => {
			const response = await createHeading(selectedHeading);
			setHeadings([
				...headings.filter(heading => heading.id !== selectedHeading.id),
				{ ...response.data, editMode: false, content: '' },
			]);
		},
		[headings, setHeadings]
	);

	// Update an existing heading
	const update = useCallback(
		async selectedHeading => {
			const response = await updateHeading(selectedHeading);
			setHeadings(
				headings?.map(heading => (heading.id === selectedHeading.id ? { ...response.data, editMode: false } : heading))
			);
		},
		[headings, setHeadings]
	);

	// Remove a heading
	const remove = useCallback(
		async selectedHeadingId => {
			await deleteHeading(selectedHeadingId);
			setHeadings(headings.filter(heading => heading.id !== selectedHeadingId));
		},
		[setHeadings]
	);

	// Save the order of headings after dragging
	const saveOrder = useCallback(
		async changedHeadings => {
			if (changedHeadings.length === 0) return;
			try {
				await reorderHeadings({ orders: changedHeadings });
				setHeadings(
					headings
						.map(heading => {
							const updatedHeading = changedHeadings.find(changed => changed.id === heading.id);
							return updatedHeading && updatedHeading !== -1
								? { ...heading, order_index: updatedHeading.order }
								: heading;
						})
						.sort((a, b) => a.order_index - b.order_index)
				);
			} catch (error) {
				console.error('Error saving order of headings:', error);
			}
		},
		[setHeadings, headings]
	);

	// Handle the drag end event to update the order of headings
	const handleDragEnd = useCallback(
		async result => {
			const updatedHeadings = updateAndGetNewOrder({ values: headings, setValues: setHeadings, result });
			await saveOrder(updatedHeadings);
		},
		[headings, saveOrder, setHeadings]
	);

	return (
		<div>
			{showLockState && (
				<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
					<FaLock style={{ marginRight: 7, transform: 'translateY(2px)' }} />
					Information on this Headings tab is never sent to AI Rewrite.
				</div>
			)}
			<DragDropContext onDragEnd={handleDragEnd}>
				<div className="headings-content" style={{ width: activeNoteType?.is_tour && isDesignPage ? '50%' : '' }}>
					<Droppable droppableId="headings">
						{(provided, snapshot) => (
							<span ref={provided.innerRef} {...provided.droppableProps}>
								{processedHeadings?.map((heading, index) => (
									<HeadingItem
										key={index}
										index={index}
										heading={heading}
										headings={headings}
										setHeadings={setHeadings}
										addNewHeading={addNewHeading}
										deleteHeading={remove}
										updateHeading={update}
										toggleEditMode={toggleEditMode}
										inputType={getInputType(heading)}
									/>
								))}
								{provided.placeholder}
							</span>
						)}
					</Droppable>
				</div>
				<div className="button-container">
					<Tippy content="Not available in demo" disabled={!isDemo}>
						<div style={{ display: 'inline-block', pointerEvents: 'auto' }}>
							<ThemeButton
								label="Add new heading"
								onClick={handleAddClick}
								icon={FaPlus}
								disabled={isDemo}
							/>
						</div>
					</Tippy>
				</div>
			</DragDropContext>
		</div>
	);
};

export default Headings;
